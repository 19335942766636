html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: Avenir-Book !important;
    //  margin: 0 0 300px;
     background-color: white !important;
}

.clear{
    clear: both;
}

.full-width{
    width: 100%;
}

.bold{
    font-weight: bold;
    font-family: 'Avenir-Black';
}

.center{
    text-align: center;
}

textarea, input{
    outline: none;
}

textarea{
    width: 100%;
}
