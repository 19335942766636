.checkbox-custom, .radio-custom {
    opacity: 0;
    position: absolute;
    outline: none;
    font-family: 'Avenir-Book';
    font-size: 1.1em;
}

.checkbox-custom, .checkbox-custom-label, .radio-custom, .radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.checkbox-custom-label, .radio-custom-label {
    position: relative;
}

.radio-custom-label, .checkbox-custom-label{
    outline: none !important;
    margin: 0;
    padding: 4px 0;
}

.checkbox-custom + .checkbox-custom-label:before, .radio-custom + .radio-custom-label:before {
    content: '';
    background: #fff;
    border: 1px solid $baseColor;
    display: inline-block;
    vertical-align: middle;
    width: 15px;
    height: 15px;
    padding: 2px;
    margin-right: 10px;
    text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
    background: $baseColor;
}

.radio-custom + .radio-custom-label:before {
    border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
    background: $baseColor;
}


.checkbox-custom:focus + .checkbox-custom-label, .radio-custom:focus + .radio-custom-label {
  outline: 1px solid #ddd; /* focus style */
}


input[type="range"] {

}

.rangeslider__fill{
    background-color: $baseColor;
}

.rangeslider--horizontal{
    height: 12px;
    margin-top: 20px;
}

.rangeslider--horizontal .rangeslider__handle{
    top: -4px;
}

.rangeslider__handle{
    height: 20px;
    width: 20px;
    box-shadow: none;
    background: white;
    background-image: none !important;

    &:after{
        background-image: none !important;
    }
}

.range-output{
    text-align: center;
    padding-top: 40px;
    font-size: 20px;
    font-family: 'Avenir-Black';
    color: $baseColor;
    margin-bottom: 15px;
}
