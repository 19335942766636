footer{
    background-color: #605f5f;
    color: white;
    margin-top: 40px;

    img{
        padding: 20px 0px;
        width: 100%;
    }

    h4 {
        padding-top: 15px;
        color: $baseColor;
        text-transform: uppercase;
        font-family: 'Avenir-Black';
    }
}


@media screen and (max-width: 1000px) {
    footer {
        img {
            width: auto;
        }
    }
}
