h2{
    font-family: 'Avenir-Black';
    color: $baseColor;
    text-transform: uppercase;
    font-size: 25px;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 25px;
}

.gegevens{
    label, input{
        width: 100%;
    }

    input[type="checkbox"]{
        width: auto;
        height: auto;
    }

    input{
        border: 1px solid $baseColor;
        height: 35px;
        margin-bottom: 15px;
        font-size: 20px;
        padding-left: 5px;
    }

    label{
        font-size: 1.2em;
    }
}

.container{
    margin-top: 60px;
}

.names{
    display: none;
}

.intro{
    padding-top: 10px;
    padding-bottom: 10px;
}

.anoniem{
    margin-top: 20px;
}

.vragen{
    input[type='radio']{
        // margin-left: 15px;
    }

    .vraag{
        margin-bottom: 5px;
        font-size: 1.3em;
    }

    .vraag-block{
        margin-bottom: 40px;
    }
}

input[type='checkbox']{
    border: 1px solid $baseColor;
}

textarea{
    border: 1px solid $baseColor;
    resize: none;
    margin-left: 15px;
    padding-left: 5px;
    padding-top: 5px;
}

.cbox{
    margin-left: 15px;
    display: inline-block;
}


.cbox-r{
    margin-left: 15px;
}

.bedankt{
    position: relative;
    text-align: center;

    .block{
        position: fixed;
        top: 50%;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);

        a{
            color: $blue !important;
            font-family: 'Avenir-Black';
        }
    }

    h3{
        font-size: 3em;
        font-family: 'Avenir-Black';
        color: $baseColor;
        margin-top: 60px;
    }
}

.anoniem-txt {
    color: red;
    display: none;
}

.prijzen{
    margin-top: 20px;
    text-align: center;

    h3{
        color: $baseColor;
        font-family: 'Avenir-black';
        text-transform: uppercase;
        margin-bottom: 20px;
    }

    img{
        width: 75%;
    }

    p{
        font-family: 'Avenir-Black';
        text-align: center;
        font-size: 20px;
        color: $baseColor;
    }
}
