
#offerteform ol li.active-step-ind p.circle, #offerteform ol li.active-step-ind p.circle span, #offerteform ol li.active-step-ind p,
#offerteform ol li.done p.circle, #offerteform ol li.done p.circle span, #offerteform ol li.done p {
    color: $baseColor !important;
    border-color: $baseColor !important;
}

#offerteform ol li.active-step-ind .arrow, #offerteform ol li.done .arrow  {
    background: $baseColor !important;
}

#offerteform ol li.active-step-ind{
    .names{
        // display: inline-block;
    }
}

#offerteform ol li.active-step-ind .arrow:after, #offerteform ol li.done .arrow:after{
    border-color:  transparent transparent transparent $baseColor !important;
}

ol{
    margin: auto;
    list-style: none;
    display: block;
    width: 100%;
    text-align: center;

    &:after{
        clear: both;
        content: "";
        display: block;
    }

    li{
        margin: 1em;
        display: inline-block;
        p.circle{
            border: 1.5px solid #bcbec0;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            position: relative;
            display: inline-block;
            margin-top: 0;
            float: left;
            margin-right: 1em;

            span{
                position: absolute;
                color: #bcbec0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 20px;
            }
        }
        p{
            display: inline-block;
            border: none;
            border-radius: 0;
            width: auto;
            height: auto;
            margin-top: 0.7em;
            color: #bcbec0;
        }
        .arrow{
            height: 2px;
            width: 25px;
            background: #bcbec0;
            display: inline-block;
            margin-left: 1em;
            position: relative;
            margin-top: 1.5em;

            &:after{
                content: "";
                display: block;
                position: absolute;
                right: -5px;
                bottom: -6px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7px 0 7px 15px;
                border-color: transparent transparent transparent #bcbec0;
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .arrow{
        width: 0 !important;
    }

    .steps-counter{
        padding: 0;
    }

    header h1 {
        font-size: 40px;
    }
}

@media screen and (max-width: 400px) {
    .cbox{
        display: block;
    }

    .bedankt .block{
        width: 100%;
    }
}
