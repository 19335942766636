@font-face {
   font-family: 'Avenir-Black';
   src: url('../fonts/AvenirLTStd-Black.eot');
   src: url('../fonts/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirLTStd-Black.woff') format('woff'),
        url('../fonts/AvenirLTStd-Black.ttf') format('truetype'),
        url('../fonts/AvenirLTStd-Black.svg#AvenirLTStd-Black') format('svg');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'Avenir-Book';
   src: url('../fonts/AvenirLTStd-Book.eot');
   src: url('../fonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirLTStd-Book.woff') format('woff'),
        url('../fonts/AvenirLTStd-Book.ttf') format('truetype'),
        url('../fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
   font-weight: normal;
   font-style: normal;
}

@font-face {
   font-family: 'Avenir-Light';
   src: url('../fonts/AvenirLTStd-Light.eot.eot');
   src: url('../fonts/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirLTStd-Light.woff') format('woff'),
        url('../fonts/AvenirLTStd-Light.ttf') format('truetype'),
        url('../fonts/AvenirLTStd-Light.svg#AvenirLTStd-Light') format('svg');
   font-weight: normal;
   font-style: normal;
}
