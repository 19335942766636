header{
    position: relative;
    background-image: url('../images/slider_waves.jpg');
    background-size: cover;
    background-position: center;
    height: 150px;
    overflow:hidden;

    .logo{
        position: relative;
        height: 300px;
        width: 300px;
        background-color: white;
        border-radius: 50%;
        margin-top: -80px;
        margin-left: 40px;
        float: left;

        img{
            position: absolute;
            width: 80%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    h1{
        color: white;
        padding-top: 30px;
        padding-left: 50px;
        float: left;
        font-size: 61px;
    }
}
