.start-btn{
    font-family: 'Avenir-Book';
    text-transform: uppercase;
    font-size: 1.2em;
    background-color: $baseColor;
    color: white;
    padding: 10px 30px;

    border: none;
    text-align: right;

    &:hover{
        text-decoration: none;
        color: white;
    }

    .more{
        padding-left: 5px;
    }
}

.btn-wrap{
    margin-top: 30px;
    text-align: right;
}

button{
    outline: none;
}
